import { render, staticRenderFns } from "./tab1.vue?vue&type=template&id=76de89f2&scoped=true&"
import script from "./tab1.vue?vue&type=script&lang=js&"
export * from "./tab1.vue?vue&type=script&lang=js&"
import style0 from "./tab1.vue?vue&type=style&index=0&id=76de89f2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76de89f2",
  null
  
)

export default component.exports